import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/avatar.jpg'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={5}>
      <Hero offset={0}>
        <BigTitle>
          Hello, <br /> I'm Dpayne.
        </BigTitle><Subtitle>I'm revamping the software development process to increase productivity and fun at work.</Subtitle>
      </Hero>
      <Projects offset={1}>
        <Title>Projects</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="Starcraft"
            link="https://www.starcraft.com"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
            I created in-game content for Starcraft Remastered in Node and React.
          </ProjectCard>
          <ProjectCard
              title="TOCA, LLC"
              link="https://apps.apple.com/us/app/toca-training/id1204741286"
              bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
            Wrote embedded and mobile software for the T2 training device.
          </ProjectCard>
          <ProjectCard
            title="House of Blues"
            link="https://www.houseofblues.com"
            bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
          >
            Built HouseofBlues.com in C#
          </ProjectCard>
          <ProjectCard
            title="Live Nation Premium Tickets"
            link="https://www.livenationpremiumtickets.com/"
            bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
          >
            Live Nation Premium Tickets
          </ProjectCard>
          <ProjectCard
            title="RiotGames"
            link="https://www.riotgames.com"
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
            Consulted RiotGames on DevOps best practices
          </ProjectCard>
          <ProjectCard
              title="Ytel"
              link="https://www.ytel.com"
              bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
            Designed a new data service layer for Ytel.
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <About offset={3}>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt="Daniel Payne" />
          <AboutSub>
            Honey Badger is my spirit animal.
          </AboutSub>
        </AboutHero>
        <AboutDesc>
          To me work isn't about offsetting the difficulty with fun, but making the actual work itself fun, rewarding, and easy
          for the development team. Properly directed energy with little wasted effort enables a developer to create the most
          efficient solutions, stakeholders to have the most visibility and control, and for everyone to have the best possible
          time doing it.
        </AboutDesc>
      </About>
      <Contact offset={4}>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>
            Say <a href="mailto:daniel.payne.unlimited@gmail.com">Hi</a> or find me on other platforms:{' '}
            <a href="https://twitter.com/dpaynesocal">Twitter</a>. &{' '}
            <a href="https://www.instagram.com/sir.shrektalicious/">Instagram</a>
          </ContactText>
        </Inner>
        <Footer>
          &copy; 2020 Daniel Payne.{' '}
          <a href="https://github.com/dpayne9000">Github Repository</a>.
        </Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
